export const memeList = [{
    id : 0,
    name: 'Soldier',
    image : './meme/33uut2.png',
     texts:[ {text:"Weapons",x:160,y:50,maxWidth:300,color: "white"},{text:"Soilder",x:270,y:220,maxWidth:300,color: "white"},{text:"Bed",x:480,y:495,maxWidth:300,color: "white"}]
},{
    id : 1,
    name: 'Y-Way',
    image : './meme/5g0z94.png',
     texts:[ {text:"Bright Castle",x:150,y:60,maxWidth:200,color: "white"},{text:"Dark Castle ",x:450,y:60,maxWidth:200,color: "white"},{text:"Crossing",x:305,y:430,maxWidth:200,color: "white"}]
},{
    id : 2,
    name: 'Dragon',
    image : './meme/3dragon.jpg',
     texts:[ {text:"Left",x:170,y:500,maxWidth:250,color: "white"},{text:"Middle",x:490,y:500,maxWidth:300,color: "white"},{text:"Right",x:850,y:500,maxWidth:300,color: "white"}]
},
{
    id : 3,
    name: 'PeterGriffin',
    image : './meme/peter-griffin.jpg',
     texts:[ {text:"Top Text",x:368,y:100,maxWidth:500,color: "red"},{text:"text2",x:1000,y:1000,maxWidth:300,color: "white"},{text:"text3",x:1000,y:1000,maxWidth:300,color: "white"}]
},
{
    id : 4,
    name: 'NotSure',
    image : './meme/not_sure.jpg',
     texts:[ {text:"Top Text",x:225,y:50,maxWidth:400,color: "white"},{text:"Bottom Text",x:225,y:330,maxWidth:400,color: "white"},{text:"text3",x:1000,y:1000,maxWidth:300,color: "white"}]
},
{
    id : 5,
    name: 'BatSlap',
    image : './meme/batman.jpg',
     texts:[ {text:"Superman",x:130,y:50,maxWidth:220,color: "black"},{text:"Batman",x:395,y:50,maxWidth:200,color: "black"},{text:"text3",x:1000,y:1000,maxWidth:300,color: "white"}]
},
{
    id : 6,
    name: 'Fire',
    image : './meme/fire.jpg',
     texts:[ {text:"House",x:150,y:300,maxWidth:250,color: "white"},{text:"Child",x:325,y:570,maxWidth:250,color: "white"},{text:"text3",x:1000,y:1000,maxWidth:300,color: "white"}]
},
{
    id : 7,
    name: 'DistractedBoyfriend',
    image : './meme/distracted_boyfriend.webp',
     texts:[ {text:"Another Girl",x:270,y:450,maxWidth:250,color: "white"},{text:"Boyfirend",x:570,y:374,maxWidth:250,color: "white"},{text:"Girlfriend",x:735,y:500,maxWidth:300,color: "white"}]
},
{
    id : 8,
    name: 'WilliWonka',
    image : './meme/willi_wonka.jpg',
     texts:[ {text:"Top",x:400,y:60,maxWidth:700,color: "white"},{text:"Bottom",x:400,y:500,maxWidth:700,color: "white"},{text:"text3",x:1000,y:1000,maxWidth:300,color: "white"}]
},
{
    id : 9,
    name: 'SuccessBaby',
    image : './meme/success_baby.jpg',
     texts:[ {text:"Top",x:248,y:50,maxWidth:480,color: "black"},{text:"Bottom",x:248,y:600,maxWidth:480,color: "black"},{text:"text3",x:1000,y:1000,maxWidth:300,color: "white"}]
},
{
    id : 10,
    name: 'ObelixCake',
    image : './meme/obelix_cake.png',
     texts:[ {text:"Top",x:160,y:50,maxWidth:300,color: "white"},{text:"Bottom",x:160,y:300,maxWidth:300,color: "white"},{text:"text3",x:1000,y:1000,maxWidth:300,color: "white"}]
},
{
    id : 11,
    name: 'LavaRiver',
    image : './meme/lavariver.png',
     texts:[ {text:"Umgebung",x:250,y:50,maxWidth:480,color: "white"},{text:"Anforderung1",x:150,y:400,maxWidth:100,color: "white"},{text:"Anforderung2",x:405,y:425,maxWidth:100,color: "white"}]
},
{
    id : 12,
    name: 'Genikdama',
    image : './meme/genkidama.jpg',
     texts:[ {text:"Umgebung",x:200,y:50,maxWidth:200,color: "black"},{text:"Bottom",x:200,y:300,maxWidth:300,color: "black"},{text:"Anforderung2",x:405,y:425,maxWidth:100,color: "white"}]
},
{
    id : 13,
    name: 'DonDarper',
    image : './meme/don_draper.webp',
     texts:[ {text:"Umgebung",x:275,y:310,maxWidth:500,color: "white"},{text:"Bottom",x:1000,y:340,maxWidth:300,color: "white"},{text:"Anforderung2",x:405,y:425,maxWidth:100,color: "white"}]
},
{
    id : 14,
    name: 'Barbie',
    image : './meme/barbie.webp',
     texts:[ {text:"Umgebung",x:275,y:55,maxWidth:500,color: "white"},{text:"Bottom",x:275,y:350,maxWidth:500,color: "white"},{text:"Anforderung2",x:4105,y:425,maxWidth:100,color: "white"}]
},
{
    id : 15,
    name: 'LeftRight',
    image : './meme/Left-Exit-12-Off-Ramp.jpg',
     texts:[ {text:"Left",x:200,y:141,maxWidth:100,color: "white"},{text:"Right",x:400,y:141,maxWidth:100,color: "white"},{text:"Anforderung2",x:4105,y:425,maxWidth:100,color: "white"}]
}]


export const memeObjects = {0:{
    id : 0,
    name: 'Soldier',
    image : './meme/33uut2.png',
     texts:[ {text:"text1",x:100,y:50,maxWidth:220,color: "white"},{text:"text2",x:220,y:50,maxWidth:220,color: "white"},{text:"text3",x:480,y:490,maxWidth:300,color: "white"}]
},1:{
    id : 1,
    name: 'Y-Way',
    image : './meme/5g0z94.png',
     texts:[ {text:"text1",x:150,y:40,maxWidth:200,color: "white"},{text:"text2 ",x:450,y:40,maxWidth:200,color: "white"},{text:"text3",x:305,y:430,maxWidth:200,color: "white"}]
},2:{
    id : 2,
    name: 'Dragon',
    image : './meme/3dragon.jpg',
     texts:[ {text:"text1",x:125,y:520,maxWidth:300,color: "white"},{text:"text2",x:490,y:520,maxWidth:300,color: "white"},{text:"text3",x:850,y:520,maxWidth:300,color: "white"}]
},
3:{
    id : 3,
    name: 'Dragon',
    image : './meme/peter-griffin.jpg',
     texts:[ {text:"text1",x:368,y:100,maxWidth:500,color: "red"}]
}}
