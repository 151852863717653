import React from 'react';
import ReactDOM from 'react-dom/client';
import {HashRouter, Route, Routes} from "react-router-dom";
import './index.css';
import './App.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import MemeGen from './MemeGen';
import Navbar from './Navbar';
import CardGenerator from './CardGenerator';
import FestivalPlaner from './FestivalPlaner';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);




root.render(
  <React.StrictMode>
   <HashRouter>
    <div className='grid'>
      <div className='nav'><Navbar></Navbar> </div>
    <div className='content'>  

  <Routes>
        <Route path="/" Component={App} />
        <Route path="/memegen" Component={MemeGen}  /> 
        <Route path="/cardgen" Component={CardGenerator}  /> 
        <Route path="/festival" Component={FestivalPlaner}  /> 
        </Routes>
    
   </div>
    </div>
     </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
