import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Canvas from './Canvas';
import { text } from 'stream/consumers';


import { memeList } from './data/memeList';
import MemeGen from './MemeGen';
import {
  Outlet,
  NavLink,
  useLoaderData,
  Form,
  redirect,
} from "react-router-dom";



function Navbar() {

 



  return (
<>
<ul>
<li className='navitem'>
<NavLink
                    to={`/`}
                    className={({ isActive, isPending }) =>  
                    isActive
                    ? " active"
                    : isPending
                    ? "pending"
                    : ""
                    }
                  > Home</NavLink>
                  </li><li className='navitem'>
                  <NavLink
                    to={`/memegen`}
                    className={({ isActive, isPending }) =>
                      isActive
                        ? "active"
                        : isPending
                        ? "pending"
                        : ""
                    }
                  > Memegenerator</NavLink></li>
                  <li className='navitem'>
                  <NavLink
                    to={`/cardgen`}
                    className={({ isActive, isPending }) =>
                      isActive
                        ? "active"
                        : isPending
                        ? "pending"
                        : ""
                    }
                  > CardGenerator</NavLink></li>
                                    <li className='navitem'>
                  <NavLink
                    to={`/festival`}
                    className={({ isActive, isPending }) =>
                      isActive
                        ? "active"
                        : isPending
                        ? "pending"
                        : ""
                    }
                  > FestivalPlaner</NavLink></li>
</ul></>
  );
}

export default Navbar;
