import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';




function FestivalPlaner() {



  return (
   <>
   <h1>FestivalPlaner</h1>
   <p> Wähle dir ein Meme aus und Tippe deine Texte ein. Danach einmal den Button "Create" drücken und dann downloaden</p>

    
      </>
  );
}

export default FestivalPlaner;
