import React, { useRef, useEffect } from 'react';

import { memeList, memeObjects } from './data/memeList';

import { info } from 'console';
import MemeGen from './MemeGen';

interface CanvasProps {
    width: number;
    height: number;
    image: string;
}

interface Text {
  text: string;
  x : number;
  y : number;
  maxWidth: number;
  color : string
}

interface MemeTexts {
     texts : Text[];
}


interface Style {
    fontSize: number;
    fontFamily: string;
    color : string; 
    textAlign : 'left'| 'right' | 'center';
    textBaseline :  'top' | 'bottom';
}

const writeText = (info:Text, style:Style, canvasRef:React.RefObject<HTMLCanvasElement>) => { 
    const canvas = canvasRef.current;
    if(canvas){
    const context = canvas.getContext('2d');
    if (context == null) throw new Error('Could not get context');  
    if(context){
    context.beginPath();
    context.font = style.fontSize + 'px '; // + style.fontFamily;
    // ctx.textAlign = style.textAlign;
    // ctx.textBaseline = style.textBaseline;
    // ctx.fillStyle = style.color;
    console.log(info)
    context.fillText(info.text, info.x, info.y);
    context.stroke();}}
}

const simpleWriteText = (info:Text, canvasRef:React.RefObject<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if(canvas){
    const context = canvas.getContext('2d');

    if (context == null) throw new Error('Could not get context');  
    if(context){
        context.font = 2+'em Arial' ; // + style.fontFamily;

      var textlength =  context.measureText(info.text)
        console.log(info.text +" \t"+textlength.width+" \t"+info.maxWidth)

        if(textlength.width >  info.maxWidth){
            var middle = Math.floor(info.text.length / 2);
            var before = info.text.lastIndexOf(' ', middle);
            var after = info.text.indexOf(' ', middle + 1);
            
            if (middle - before < after - middle) {
                middle = before;
            } else {
                middle = after;
            }
            
            var s1 = info.text.substr(0, middle);
            var s2 = info.text.substr(middle + 1);
            console.log(s1);
            console.log(s2);
            context.beginPath();
    context.textAlign = "center";
    // ctx.textBaseline = style.textBaseline;
    context.fillStyle = info.color ? info.color : "rgb(255,255,255)";
    context.strokeText(s1, info.x, info.y, info.maxWidth);
    context.fillText(s1, info.x, info.y, info.maxWidth);
    context.stroke();
    context.beginPath();
    context.textAlign = "center";
    // ctx.textBaseline = style.textBaseline;
    context.fillStyle = info.color ? info.color : "rgb(255,255,255)";
    context.strokeText(s2, info.x, info.y+40, info.maxWidth);
    context.fillText(s2, info.x, info.y+40, info.maxWidth);
    context.stroke();
        }else{
    context.beginPath();
    context.textAlign = "center";
    // ctx.textBaseline = style.textBaseline;
    context.fillStyle = info.color ? info.color : "rgb(255,255,255)";
    context.strokeText(info.text, info.x, info.y, info.maxWidth);
    context.fillText(info.text, info.x, info.y, info.maxWidth);
    context.stroke();}}
}}

const Canvas = ({ width, height,image}: CanvasProps) => {

    const [ImageDrawn, setImageDrawn] = React.useState<boolean>(false);
    const [texts, setTexts] = React.useState<Array<Text>>([]);
    var textObjects = memeObjects;

    console.log(memeList[Number(image)].texts);
    
   const text1 = React.createRef<HTMLInputElement>();
   const text2 = React.createRef<HTMLInputElement>();
   const text3 = React.createRef<HTMLInputElement>();
   
   const [imageName, setImageName] = React.useState<string>('');

    const canvasRef = useRef<HTMLCanvasElement>(null);
    useEffect(() => {
        setTexts(memeList[Number(image)].texts)

        if (canvasRef.current) {
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
            if (context == null) throw new Error('Could not get context');  
            if(context){
                setImageName(memeList[Number(image)].name);
                const img = new Image(); // Create new img element
                img.src = memeList[Number(image)].image; // Set source path
                canvas.height = img.height;
                canvas.width = img.width;
                img.onload = () => {
                    context.drawImage(img, 0, 0,img.width, img.height);
                    setImageDrawn(true);

                  };

               
                }
   
          }
            
    },[image]);

    function createMeme(){

        texts.map(element => (

            console.log(document.getElementById(element.text))

        ))

        if(text1.current?.value){
        memeList[Number(image)].texts[0].text =text1.current?.value;}
        if(text2.current?.value){
            memeList[Number(image)].texts[1].text =text2.current?.value;}
            if(text3.current?.value){
                memeList[Number(image)].texts[2].text =text3.current?.value;}
        memeList[Number(image)].texts.forEach(element => {
            
            // var text =  text1.current?.value? text1.current?.value :" ";
            // console.log(text1.current?.value)
            // console.log(text2.current?.value)
            // console.log(text3.current?.value)
            simpleWriteText(element,canvasRef);
/*             if( /document.getElementById(element).value != null ){
               // text= document.getElementById(element).value;
                console.log(document.getElementById(element))
            }
            else{ text = " ";

            }
            */

           // simpleWriteText(element.text,canvasRef);
        });
    }

    function downloadMeme(){
       
        if (canvasRef.current) {
            const canvas = canvasRef.current;
            var image =canvas.toDataURL("image/png");
               

            downloadImage(image, imageName+'_'+text1.current?.value+'_'+text2.current?.value+'_'+text3.current?.value+'.png');
        } 


           // simpleWriteText(element.text,canvasRef);
        
    }

    function downloadImage(data:string, filename = 'untitled.jpeg') {
        var a = document.createElement('a');
        a.href = data;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
    }

    return  <>            <div>
                {/* {texts.map(element => (

                    <input type="text"  ref={text1} id={element.text} placeholder={'Text'} ></input>
                ))} */}

                <input type="text"  ref={text1} id="text1" placeholder={memeList[Number(image)].texts[0].text}></input>
                <input type="text" ref={text2}  id="text2" placeholder={memeList[Number(image)].texts[1].text}></input>
                <input type="text" ref={text3} id="text3"placeholder={memeList[Number(image)].texts[2].text}></input>
              
                <button id="createButton" onClick={createMeme}>Create</button>
                <button id="downloadButton" onClick={downloadMeme}>Download</button>

            </div><div> 
                <canvas ref={canvasRef} height={height} width={width} /></div>
</>;
};

Canvas.defaultProps = {
    width: window.innerWidth,
    height: window.innerHeight
};

export default Canvas;