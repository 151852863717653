import React, { useState, useRef } from 'react';
import logo from './logo.svg';
import './App.css';
import Canvas from './Canvas';
import { text } from 'stream/consumers';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

import { memeList } from './data/memeList';
import { url } from 'inspector';

import startImage from './placeholder.png';

function CardGenerator() {
  const [meme,setMeme] = useState('')
  const cardContainerRef  = useRef<HTMLDivElement>(null)



const [nameValue,setNameValue] = useState("Name der Karte");
const nameRef  = useRef<HTMLInputElement>(null)


const [typeValue,setTypeValue] = useState("Type");
const typeRef  = useRef<HTMLInputElement>(null)

const [powerValue,setPowerValue] = useState("70KP");
const powerRef  = useRef<HTMLInputElement>(null)


const [colorCard,setColorCard] = useState("#e66465");
const colorCardRef  = useRef<HTMLInputElement>(null)



const [userImage, setUserImage] = useState(startImage);
const [selectedImage, setSelectedImage] = useState<string>(startImage);

const [attackName1,setAttackName1] = useState("Attacke 1");
const attackRef1  = useRef<HTMLInputElement>(null)

const [attackName2,setAttackName2] = useState("Attacke 2");
const attackRef2  = useRef<HTMLInputElement>(null)

const [attackName3,setAttackName3] = useState("Attacke 3");
const attackRef3  = useRef<HTMLInputElement>(null)

const [attackName1V,setAttackName1V] = useState("1/5");
const attackRef1V  = useRef<HTMLInputElement>(null)

const [attackName2V,setAttackName2V] = useState("2/5");
const attackRef2V  = useRef<HTMLInputElement>(null)

const [attackName3V,setAttackName3V] = useState("5/5");
const attackRef3V  = useRef<HTMLInputElement>(null)


const [cardtext,setCardText] = useState("Beschreibungstext");
const textAreaRef  = useRef<HTMLTextAreaElement>(null)
 
function handleImage(e:any) {
  setSelectedImage(URL.createObjectURL(e.target.files[0]))
  console.log(selectedImage);

  //setUserImage(URL.createObjectURL(e.target.files[0]));
}




function handleClick() {
  // 👇️ update input value

  // 👇️ access input value
  if(nameRef.current){    setNameValue(nameRef.current.value);}
  if(typeRef.current){    setTypeValue(typeRef.current.value);}

  if(powerRef.current){    setPowerValue(powerRef.current.value);}

  if(attackRef1.current){ setAttackName1(attackRef1.current.value);}
  if(attackRef2.current){    setAttackName2(attackRef2.current.value);}
  if(attackRef3.current){    setAttackName3(attackRef3.current.value);}

  if(attackRef1V.current){ setAttackName1V(attackRef1V.current.value);}
  if(attackRef2V.current){    setAttackName2V(attackRef2V.current.value);}
  if(attackRef3V.current){    setAttackName3V(attackRef3V.current.value);}

  if(textAreaRef.current){    setCardText(textAreaRef.current.value);}

}
 
function downloadCard(){

  if(document.getElementById('cardBody')){
    var element = document.getElementById('cardBody');
    console.log(element)
    console.log(typeof(element))
    if(element!=null){
htmlToImage.toPng(element).then(function (dataUrl) {
  var link = document.createElement('a');
  link.download = 'card_'+nameValue+'.png';
  link.href = dataUrl;
  link.click();
});}
  }



    //html2canvas()
//   if(el){
//   html2canvas(el).then(canvas => {

//       var image =canvas.toDataURL("image/png");
         

//       downloadImage(image);
  
// } );
}

function changeColor(e:any){
  if(document.getElementById("cardDesign")!=null){
    var cardDesign = document.getElementById("cardDesign");
    if(cardDesign)
  cardDesign.style.backgroundColor=e.target.value;}
}


  return (
   <>
   <h1>CardGenerator</h1>
   <p> Wähle dir ein Meme aus und Tippe deine Texte ein. Danach einmal den Button "Create" drücken und dann downloaden</p>
{/*       <div className='memeChooser'>
      {memeList.map(element => (
 <button id={element.id.toString()} key={element.id} onClick={handleSubmit}><img id ={element.id.toString()}  className="memeSelect" title={element.name } alt={element.name } src={element.image}/></button>
      ))}

  </div>
     
      <Canvas image={meme} ></Canvas>
     */}
     <div className='cardContainer'  ref={cardContainerRef} id='cardContainer'>
      <div className='cardInput'>
     <div className='cardInputRow'><label htmlFor="name">Name</label> <input type='text' id="name" ref={nameRef} defaultValue={nameValue} /></div>
     <div className='cardInputRow'><label htmlFor="typeCard">Type</label> <input type='text' id="typeCard" ref={typeRef} defaultValue={typeValue} /></div>

     <div className='cardInputRow'><label htmlFor="power">Power</label>  <input type='text' id="power" ref={powerRef} defaultValue={powerValue} /></div>

     <div className='cardInputRow'><label htmlFor="cardColor">Color</label> <input type="color" id="cardColor" name="cardColor" value="#e66465" onChange={changeColor} /></div>
     <div className='cardInputRow'><label htmlFor="cardtext">Text</label>  <textarea id="cardtext" ref={textAreaRef} maxLength={205}  placeholder={cardtext} rows={4} cols={52} defaultValue={cardtext}>
</textarea></div>
   
<div className='cardInputRow'><label htmlFor="attack1">Attacke 1</label> <input type='text' id="attack1" ref={attackRef1} defaultValue={attackName1}  /></div>
<div className='cardInputRow'><label htmlFor="attack1v">Attacke 1 Wert</label>   <input type='text' id="attack1v" ref={attackRef1V} defaultValue={attackName1V} /></div>
<div className='cardInputRow'><label htmlFor="attack2">Attacke 2</label>    <input type='text' id="attack2" ref={attackRef2} defaultValue={attackName2} /></div>
<div className='cardInputRow'><label htmlFor="attack2v">Attacke 3 Wert</label>   <input type='text' id="attack2v" ref={attackRef2V} defaultValue={attackName2V} /></div>
<div className='cardInputRow'><label htmlFor="attack3">Attacke 3</label>    <input type='text' id="attack3" ref={attackRef3} defaultValue={attackName3} /></div>
<div className='cardInputRow'><label htmlFor="attack3v">Attacke 3 Wert</label>   <input type='text' id="attack3v" ref={attackRef3V} defaultValue={attackName3V} /></div>



<div className='cardInputRow'><label htmlFor="cardImage">Image Upload</label> <input type="file" id="cardImage" onChange={handleImage} /></div>  <button onClick={handleClick}>Erstelle Karteinhalt</button>
     <button onClick={downloadCard} id="downloadCard">Download</button>

      </div>
     <div className='cardBody' id="cardBody">
      <div id="cardDesign" className='cardDesign'>
        <div className='cardLayout'>
          <div className='cardTitle'>
            <div id="cardName">{nameValue}</div>
            <div id="cardPower">{powerValue}</div>
          </div>
          <div className='cardImageContainer'>
            <img className='cardImage'  src={selectedImage}></img>
          </div>
          <div className='cardText'>
            <div className='cardType'>{typeValue}</div>
            <div>{cardtext}</div>
            <div className='cardTextEntry'>
            <div className='cardTextEntryItem'>{attackName1}</div>
            <div className='cardTextEntryValue'>{attackName1V}</div>
            </div>

            
            <div className='cardTextEntry'>
            <div className='cardTextEntryItem'>{attackName2}</div>
            <div className='cardTextEntryValue'>{attackName2V}</div>
            </div>
            
            <div className='cardTextEntry'>
            <div className='cardTextEntryItem'>{attackName3}</div>
            <div className='cardTextEntryValue'>{attackName3V}</div>
            </div>
            
            </div>


        </div>
      </div>
     </div>
     </div>
      </>
  );
}

export default CardGenerator;
