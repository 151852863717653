import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Canvas from './Canvas';
import { text } from 'stream/consumers';


import { memeList } from './data/memeList';
import MemeGen from './MemeGen';
import {
  Outlet,
  NavLink,
  useLoaderData,
  Form,
  redirect,
} from "react-router-dom";



function App() {


  return (
<><h1>Tools and Gimicks</h1>

</>
  );
}

export default App;
