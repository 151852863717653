import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Canvas from './Canvas';
import { text } from 'stream/consumers';


import { memeList } from './data/memeList';



interface Text {
  text: string;
  x : number;
  y : number;
  maxWidth: number;
  color : string
}
interface MemeTexts {
  texts : Text[];
}


function MemeGen() {
  const [meme,setMeme] = useState('')
 

function handleSubmit(e:any) {
  e.preventDefault();   
  setMeme(e.target.id)
}



  return (
   <>
   <h1>Memegenerator</h1>
   <p> Wähle dir ein Meme aus und Tippe deine Texte ein. Danach einmal den Button "Create" drücken und dann downloaden</p>
      <div className='memeChooser'>
      {memeList.map(element => (
 <button  className="memeSelectButton" id={element.id.toString()} key={element.id} onClick={handleSubmit}><img id ={element.id.toString()}  className="memeSelect" title={element.name } alt={element.name } src={element.image}/></button>
      ))}

  </div>
     
      <Canvas image={meme} ></Canvas>
    
      </>
  );
}

export default MemeGen;
